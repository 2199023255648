import React, { useState, useEffect } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

function YouTube(props) {
    let width = 400;
    if (typeof window !== "undefined") {
        width = useWindowDimensions().width;
    }
    const aspectRatio = 16 / 9;
    let scale = 0.8;
    if (width > 600) {
        scale = 0.6;
    }
    return (
        <div className="my-16">
            <h1 className="lll-title font-bold text-center text-lll-purple-600 text-2xl sm:text-3xl">
                {props.title}
            </h1>
            <div className="mx-auto w-full">
                <LazyLoadComponent>
                    <iframe
                        className="mx-auto"
                        src={`https://www.youtube.com/embed/${props.youtubeId}`}
                        width={scale * width}
                        height={(scale / aspectRatio) * width}
                    ></iframe>
                </LazyLoadComponent>
            </div>
        </div>
    );
}

export default YouTube;

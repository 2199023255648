import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import YouTube from "../components/YouTube";
import LucilleSitting from "../images/LucilleSitting.jpg";

function Lucille() {
  return (
    <Layout>
      <SEO keywords={["Lucille Fleming", "centenarian"]} title="Lucille" />

      <section>
        <img
          className="rounded-full mx-auto"
          alt="Lucille Fleming smiling and sitting"
          src={LucilleSitting}
        />
        <p className="text-lll-purple-600 my-4">
          {`Lucille was born on June 24, 1912 on a farm near Cardigan, Prince Edward Island, Canada. Leaving with just once suitcase, she immigrated to the United States in 1929. She put herself through college and graduated as a Registered Nurse in 1935 from Mt. Auburn School of Nursing in Cambridge, MA.`}
        </p>
        <p className="text-lll-purple-600 my-4">
          {`After relocating to California, she met her husband Joe B. on a blind date which she reluctantly agreed to. The two were married four months later in November of 1945. She and her husband moved to Texas in 1954. Whether it was Massachusetts, California, or Texas, she continued her nursing career. Her passion for nursing was only matched by her passion and love for the Texas Rangers.`}
        </p>
        <p className="text-lll-purple-600 my-4">
          {`Judy and Lucille met in 2012 while Judy was working on the book `}
          <span className="italic">{`Age to Perfection: How to Thrive to 100, Happy, Healthy, and Wise`}</span>
          {`. What started as an interview of Lucille, turned into an incredible friendship that lasted almost four years. Their adventures were incredible, but Lucille’s favorite claim to fame came in the summer of 2015, following her 103rd birthday, when she threw out the first pitch for her beloved baseball team.`}
        </p>
        <p className="text-lll-purple-600 my-4">
          {`Lucille was a magnet, as everyone who caught a glimpse of her felt the need to engage her in conversation, or request a hug. Her energy was contagious and she could light up a room by simply entering it. Her style was one for the ages – always with a ribbon in her hair and a flower on her lapel.  As a mother, aunt, sister, grandmother, and more, Lucille knew how to be an enduring friend, how to listen and not judge, and to offer just the right amount of advice. She also had impeccable integrity matched with boundless charm, and a mind that was sharper than most of those a quarter of her age. `}
        </p>
        <p className="text-lll-purple-600 my-4">
          {`In the fullness of her lifetime she also painted, wrote poems, mastered duplicate bridge, made the best apple pies, and eventually discovered one last title that would take her over the finish line – “Longevity Expert”`}
        </p>
        <YouTube
          title={"Judy interviewing Lucille"}
          youtubeId={"zu7a6M3rU4g"}
        />
        <YouTube
          title={"Lucille throws the first pitch at a Rangers game"}
          youtubeId={"oiH0uOKUtxA"}
        />
        <YouTube title={"Lucille featured on TV"} youtubeId={"vqbHqr5B8_k"} />
      </section>
    </Layout>
  );
}

export default Lucille;
